<template>
  <MRow :gutter="28">
    <MCol>
      <MRow :gutter="0">
        <MCol>
          <h6 class="text-primary-alt font-semibold">{{ $t('other_info') }}</h6>
        </MCol>
      </MRow>
      <slot name="before"></slot>
      <MRow
        v-if="
          (!allowedFields || allowedFields.indexOf('categoryId') >= 0) &&
          hiddenFields.indexOf('categoryId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('categoryId') }"
            >
              {{ $tc('category') }}
            </small>
          </div>
          <div id="category-picker">
            <FlotoCategoryPicker
              :value="value.categoryId"
              :suggested-value="suggestedCategoryId"
              :class="{
                'read-only material-input': disabled,
                'material-input': !disabled,
              }"
              :full-path="true"
              :disabled="
                disabled || singleFieldRulesStateFn('categoryId').disable
              "
              :placeholder="disabled ? '---' : undefined"
              class="w-full"
              :module-name="moduleName"
              :allow-clear="!isRequiredFn('categoryId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('categoryId').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('categoryId').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ categoryId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          (!allowedFields || allowedFields.indexOf('departmentId') >= 0) &&
          hiddenFields.indexOf('departmentId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('departmentId') }"
            >
              {{ $tc('department') }}
            </small>
          </div>
          <div id="department-picker">
            <FlotoDepartmentPicker
              :value="value.departmentId"
              :class="{ 'read-only': disabled }"
              input-classes="material-input"
              :disabled="
                disabled || singleFieldRulesStateFn('departmentId').disable
              "
              full-path
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('departmentId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('departmentId').hiddenOptionsKeys ||
                  [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('departmentId').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ departmentId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          (!allowedFields || allowedFields.indexOf('companyId') >= 0) &&
          enableMsp
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('companyId') }"
            >
              {{ $tc('company') }}
            </small>
          </div>
          <div id="company-picker">
            <CompanyPicker
              :value="value.companyId"
              :class="{ 'read-only': disabled }"
              input-classes="material-input"
              :disabled="
                disabled || singleFieldRulesStateFn('companyId').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('companyId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('companyId').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('companyId').visibleOptionsKeys || [],
              }"
              @change="handleChange({ companyId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          (!allowedFields || allowedFields.indexOf('sourceId') >= 0) &&
          hiddenFields.indexOf('sourceId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('sourceId') }"
            >
              {{ $t('source') }}
            </small>
          </div>
          <div id="source-picker">
            <FlotoSourcePicker
              :value="value.sourceId"
              :class="{ 'read-only': disabled }"
              input-classes="material-input"
              :disabled="
                disabled || singleFieldRulesStateFn('sourceId').disable
              "
              :allow-clear="false"
              :placeholder="disabled ? '---' : undefined"
              as-input
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('sourceId').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('sourceId').visibleOptionsKeys || [],
              }"
              @change="handleChange({ sourceId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          (!allowedFields || allowedFields.indexOf('locationId') >= 0) &&
          hiddenFields.indexOf('locationId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('locationId') }"
            >
              {{ $tc('location') }}
            </small>
          </div>
          <div id="location-picker">
            <FlotoLocationPicker
              :value="value.locationId"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('locationId').disable
              "
              :placeholder="disabled ? '---' : undefined"
              class="material-input w-full"
              full-path
              :allow-clear="!isRequiredFn('locationId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('locationId').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('locationId').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ locationId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          (!allowedFields || allowedFields.indexOf('vendorId') >= 0) &&
          hiddenFields.indexOf('vendorId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('vendorId') }"
            >
              {{ $tc('vendor') }}
            </small>
          </div>
          <div id="vendor-picker">
            <VendorPicker
              :value="value.vendorId"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('vendorId').disable
              "
              :placeholder="disabled ? '---' : undefined"
              class="material-input w-full"
              :allow-clear="!isRequiredFn('vendorId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('vendorId').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('vendorId').visibleOptionsKeys || [],
              }"
              @change="handleChange({ vendorId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.PROBLEM &&
          hiddenFields.indexOf('businessServiceId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('businessServiceId'),
              }"
            >
              {{ $t('business') }} {{ $tc('service') }}
            </small>
          </div>
          <div id="business-service-picker">
            <FlotoBusinessServicePicker
              :value="value.businessServiceId"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('businessServiceId')"
              @change="handleChange({ businessServiceId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.PROBLEM &&
          hiddenFields.indexOf('natureOfProblem') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('natureOfProblem'),
              }"
            >
              {{ $t('nature_of_problem') }}
            </small>
          </div>
          <div id="nature-of-problem-dropdown">
            <FlotoDropdownPicker
              :value="value.natureOfProblem"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('natureOfProblem')"
              :options="[
                { text: $t('proactive'), key: 'proactive' },
                { text: $t('reactive'), key: 'reactive' },
              ]"
              @change="handleChange({ natureOfProblem: $event || null })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.PROBLEM &&
          hiddenFields.indexOf('knownError') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('knownError') }"
            >
              {{ $t('known_error') }}
            </small>
          </div>
          <div id="known-error-dropdown">
            <FlotoDropdownPicker
              :value="value.knownError"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('knownError')"
              :options="[
                { text: $t('yes'), key: 'true' },
                { text: $t('no'), key: 'false' },
              ]"
              @change="handleChange({ knownError: $event === 'true' })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('reasonType') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('reasonType') }"
            >
              {{ $tc('change') }} {{ $t('reason') }}
            </small>
          </div>
          <div id="reason-type-picker">
            <FlotoReasonTypePicker
              :value="value.reasonType"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('reasonType').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('reasonType')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('reasonType').hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('reasonType').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ reasonType: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('changeTypeId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('changeTypeId') }"
            >
              {{ $tc('change_type') }}
            </small>
          </div>
          <div id="change-type-dropdown">
            <FlotoChangeTypePicker
              :value="value.changeTypeId"
              input-classes="material-input"
              :disabled="
                disabled ||
                changeStage === stageConstants.APPROVAL ||
                singleFieldRulesStateFn('changeTypeId').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('changeTypeId')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('changeTypeId').hiddenOptionsKeys ||
                  [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('changeTypeId').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ changeTypeId: $event })"
            />
          </div>
        </MCol>
      </MRow>

      <MRow
        v-if="
          moduleName === $constants.RELEASE &&
          hiddenFields.indexOf('reasonType') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('reasonType') }"
            >
              {{ $tc('release') }} {{ $t('reason') }}
            </small>
          </div>
          <div id="release-reason-type-picker">
            <ReleaseReasonTypePicker
              :value="value.reasonType"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('reasonType')"
              @change="handleChange({ reasonType: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.RELEASE &&
          hiddenFields.indexOf('releaseTypeId') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{ 'field-lable-required': isRequiredFn('releaseTypeId') }"
            >
              {{ $tc('release_type') }}
            </small>
          </div>
          <div id="release-type-dropdown">
            <ReleaseTypePicker
              :value="value.releaseTypeId"
              input-classes="material-input"
              :disabled="disabled || changeStage === stageConstants.APPROVAL"
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('releaseTypeId')"
              @change="handleChange({ releaseTypeId: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.RELEASE &&
          hiddenFields.indexOf('affectedServices') === -1
        "
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('affectedServices'),
              }"
            >
              {{ $t('business') }} {{ $tc('service') }}
            </small>
          </div>
          <div id="business-service-picker">
            <FlotoBusinessServicePicker
              :value="value.affectedServices"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :style="{ lineHeight: disabled ? 2.3 : '' }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              multiple
              show-divider
              :allow-clear="!isRequiredFn('affectedServices')"
              @change="handleChange({ affectedServices: $event })"
            />
          </div>
          <MDivider class="mb-0 mt-0" />
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('targetEnvironment') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('targetEnvironment'),
              }"
            >
              {{ $t('target_environment') }}
            </small>
          </div>
          <div id="target-environment-picker">
            <FlotoTargetEnvironmentPicker
              :value="value.targetEnvironment"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('targetEnvironment').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('targetEnvironment')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('targetEnvironment')
                    .hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('targetEnvironment')
                    .visibleOptionsKeys || [],
              }"
              @change="handleChange({ targetEnvironment: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('changeImplementor') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('changeImplementor'),
              }"
            >
              {{ $t('implementer') }}
            </small>
          </div>
          <div id="change-implementer-dropdown">
            <FlotoTechnicianPicker
              :value="value.changeImplementor"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('changeImplementor').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('changeImplementor')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('changeImplementor')
                    .hiddenOptionsKeys || [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('changeImplementor')
                    .visibleOptionsKeys || [],
                filterData:
                  singleFieldRulesStateFn('changeImplementor').filterData || {},
              }"
              @change="handleChange({ changeImplementor: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow v-if="moduleName === $constants.RELEASE" class="my-3">
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('release') }} {{ $t('engineer') }}
            </small>
          </div>
          <div id="release-engineer-dropdown">
            <FlotoTechnicianPicker
              :value="value.releaseEngineer"
              input-classes="material-input"
              :class="{
                'read-only': disabled || changeStage === stageConstants.TESTING,
              }"
              :disabled="disabled || changeStage === stageConstants.TESTING"
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ releaseEngineer: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('changeManager') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('changeManager'),
              }"
            >
              {{ $t('manager') }}
            </small>
          </div>
          <div if="manager-dropdown">
            <FlotoTechnicianPicker
              :value="value.changeManager"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('changeManager').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('changeManager')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('changeManager').hiddenOptionsKeys ||
                  [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('changeManager').visibleOptionsKeys ||
                  [],
                filterData:
                  singleFieldRulesStateFn('changeManager').filterData || {},
              }"
              @change="handleChange({ changeManager: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow v-if="moduleName === $constants.RELEASE" class="my-3">
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('release') }} {{ $t('manager') }}
            </small>
          </div>
          <div id="release-manager-dropdown">
            <FlotoTechnicianPicker
              :value="value.releaseManager"
              input-classes="material-input"
              :class="{
                'read-only':
                  disabled ||
                  changeStage === stageConstants.BUILD ||
                  changeStage === stageConstants.TESTING ||
                  changeStage === stageConstants.DEPLOYMENT,
              }"
              :disabled="
                disabled ||
                changeStage === stageConstants.BUILD ||
                changeStage === stageConstants.TESTING ||
                changeStage === stageConstants.DEPLOYMENT
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ releaseManager: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow v-if="moduleName === $constants.RELEASE" class="my-3">
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('qa') }} {{ $t('manager') }}
            </small>
          </div>
          <div id="qa-manager-dropdown">
            <FlotoTechnicianPicker
              :value="value.qaManager"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="disabled"
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ qaManager: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.CHANGE &&
          hiddenFields.indexOf('changeReviewer') === -1
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small
              class="sidebar-field-lable"
              :class="{
                'field-lable-required': isRequiredFn('changeReviewer'),
              }"
            >
              {{ $t('reviewer') }}
            </small>
          </div>
          <div id="change-reviewer-dropdown">
            <FlotoTechnicianPicker
              :value="value.changeReviewer"
              input-classes="material-input"
              :class="{
                'read-only':
                  changeStage === stageConstants.REVIEW ? false : disabled,
              }"
              :disabled="
                changeStage === stageConstants.REVIEW
                  ? false
                  : disabled ||
                    singleFieldRulesStateFn('changeReviewer').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              :allow-clear="!isRequiredFn('changeReviewer')"
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('changeReviewer').hiddenOptionsKeys ||
                  [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('changeReviewer')
                    .visibleOptionsKeys || [],
                filterData:
                  singleFieldRulesStateFn('changeReviewer').filterData || {},
              }"
              @change="handleChange({ changeReviewer: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow v-if="moduleName === $constants.RELEASE" class="my-3">
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('release') }} {{ $t('reviewer') }}
            </small>
          </div>
          <div id="release-reviewer-dropdown">
            <FlotoTechnicianPicker
              :value="value.releaseReviewer"
              input-classes="material-input"
              :class="{
                'read-only':
                  changeStage === stageConstants.REVIEW ? false : disabled,
              }"
              :disabled="
                changeStage === stageConstants.REVIEW ? false : disabled
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              @change="handleChange({ releaseReviewer: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          (!allowedFields || allowedFields.indexOf('supportLevel') >= 0)
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('support_level') }}
            </small>
          </div>
          <div id="support-level-picker">
            <FlotoSupportLevelPicker
              :value="value.supportLevel"
              input-classes="material-input"
              :class="{ 'read-only': disabled }"
              :disabled="
                disabled || singleFieldRulesStateFn('supportLevel').disable
              "
              :placeholder="disabled ? '---' : undefined"
              as-input
              v-bind="{
                hiddenOptionsKeys:
                  singleFieldRulesStateFn('supportLevel').hiddenOptionsKeys ||
                  [],
                visibleOptionsKeys:
                  singleFieldRulesStateFn('supportLevel').visibleOptionsKeys ||
                  [],
              }"
              @change="handleChange({ supportLevel: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="!allowedFields || allowedFields.indexOf('violatedSlaId') >= 0"
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('current_sla') }}
            </small>
          </div>
          <div id="sla">
            <SlaName :sla-id="value.violatedSlaId" :module-name="moduleName" />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          !allowedFields ||
          (allowedFields.indexOf('transitionModelId') >= 0 && !isPortalLogin)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $t('transition_model') }}
            </small>
          </div>
          <div id="transition-model" class="ant-input">
            <FlotoTransitionModelPicker
              v-model="value.transitionModelId"
              placeholder="---"
              disabled
              text-only
              class="material-input"
              :service-catalog-id="value.serviceCatalogId"
              :module-name="
                value.serviceCatalogId ? $constants.SERVICE_CATALOG : moduleName
              "
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          [
            [
              $constants.REQUEST,
              $constants.PROBLEM,
              $constants.CHANGE,
              $constants.RELEASE,
            ].indexOf(moduleName) >= 0,
          ] &&
          (!allowedFields || allowedFields.indexOf('escalationLevel') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $t('escalation_level') }}
            </small>
          </div>
          <div id="escalation-level" class="ant-input">
            {{ value.escalationLevel }}
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          (!allowedFields || allowedFields.indexOf('responseDue') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('first_response_due_by') }}
            </small>
          </div>
          <div id="first-response-time" class="ant-input">
            <span v-if="value.responseDue >= 0">
              {{ value.responseDue | datetime }}
            </span>
            <span v-else>
              {{ value.oldResponseDue | datetime }}
            </span>
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          (!allowedFields || allowedFields.indexOf('firstResponseTime') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('first_response_date') }}
            </small>
          </div>
          <div id="first-response-date" class="ant-input">
            {{
              !value.firstResponseTime
                ? '---'
                : value.firstResponseTime | datetime
            }}
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          value.incomingMailbox &&
          (!allowedFields || allowedFields.indexOf('incomingMailbox') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $t('incoming_mail_box') }}
            </small>
          </div>
          <div id="incoming-mailbox-picker" class="ant-input">
            <FlotoTagsPicker
              :value="[value.incomingMailbox]"
              type="email"
              disabled
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="!allowedFields || allowedFields.indexOf('ccEmailSet') >= 0"
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('cc_email', 2) }}
            </small>
          </div>
          <div id="cc-emailset-picker" class="bordered-bottom">
            <FlotoTagsPicker
              :value="value.ccEmailSet"
              :title="$tc('cc_email')"
              type="email"
              :confirm-before-remove="true"
              :disabled="
                disabled || singleFieldRulesStateFn('ccEmailSet').disable
              "
              :placeholder="disabled ? '---' : undefined"
              show-empty
              class="mb-1"
              @change="handleChange({ ccEmailSet: $event })"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          value.callFrom &&
          (!allowedFields || allowedFields.indexOf('callFrom') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('call') }} {{ $tc('from') }}
            </small>
          </div>
          <div id="call-from" class="ant-input">
            {{ value.callFrom || '---' }}
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="!allowedFields || allowedFields.indexOf('templateId') >= 0"
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('template') }}
            </small>
          </div>
          <div id="template-picker" class="ant-input">
            <FlotoTemplatePicker
              :value="value.templateId"
              :module-name="moduleName"
              disabled
              placeholder="---"
              text-only
              class="material-input"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          value.requestType &&
          (!allowedFields || allowedFields.indexOf('requestType') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('request_type') }}
            </small>
          </div>
          <div id="request-type" class="ant-input">
            {{ $tc(value.requestType) }}
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          moduleName === $constants.REQUEST &&
          value.requestAge &&
          (!allowedFields || allowedFields.indexOf('requestAge') >= 0)
        "
        class="my-3"
      >
        <MCol class="material-input">
          <div>
            <small class="sidebar-field-lable">
              {{ $tc('request_age') }}
            </small>
          </div>
          <div id="ticket-age" class="ant-input">
            <FlotoDueDatePicker
              :value="value.requestAge"
              disabled
              :module-name="moduleName"
              :status-id="resource.statusId"
              is-show-age
              icon-size="2x"
            />
          </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          !isPortalLogin || (isPortalLogin && moduleName === $constants.CHANGE)
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('last_updated_date') }}
            </small>
          </div>
          <div id="updated-at">
            {{ value.updatedAt | datetime }}
          </div>
          <div id="updated-at"> ({{ value.updatedAt | timeago }}) </div>
        </MCol>
      </MRow>
      <MRow v-if="value.resolvedAt > 0 && !isPortalLogin" class="my-3">
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('last_resolved_time') }}
            </small>
          </div>
          <div id="resolved-at">
            {{ value.resolvedAt | datetime('ddd, MMM DD, YYYY hh:mm A') }}
          </div>
          <div id="resolved-at"> ({{ value.resolvedAt | timeago }}) </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          value.closedAt > 0 &&
          (!isPortalLogin ||
            (isPortalLogin && moduleName === $constants.CHANGE))
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('last_closed_time') }}
            </small>
          </div>
          <div id="closed-at">
            {{ value.closedAt | datetime('ddd, MMM DD, YYYY hh:mm A') }}
          </div>
          <div id="closed-at"> ({{ value.closedAt | timeago }}) </div>
        </MCol>
      </MRow>
      <MRow
        v-if="
          [$constants.REQUEST, $constants.PROBLEM].indexOf(moduleName) >= 0 &&
          (isTicketClosed || isTicketResolve) &&
          !isPortalLogin
        "
        class="my-3"
      >
        <MCol>
          <div>
            <small class="sidebar-field-lable">
              {{ $t('slaModule.resolution_time') }}
            </small>
          </div>
          <div id="resolution-time">
            <span v-if="value.resolutionTime === 0">0 {{ $tc('seconds') }}</span
            ><span v-else>{{ value.resolutionTime | duration }}</span>
          </div>
        </MCol>
      </MRow>
    </MCol>
  </MRow>
</template>

<script>
import ReleaseReasonTypePicker from '@components/data-picker/release-reason-type-picker'
import SlaName from '@components/sla-name'
import { stageConstants } from '@utils/status'
import { authComputed } from '@state/modules/auth'
import ReleaseTypePicker from '@components/data-picker/release-type-picker'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'
import CompanyPicker from '@components/data-picker/company-picker'
import VendorPicker from '@components/data-picker/vendor-picker.vue'

export default {
  name: 'Sidebar',
  components: {
    ReleaseReasonTypePicker,
    SlaName,
    ReleaseTypePicker,
    CompanyPicker,
    VendorPicker,
  },
  inject: ['suggestedFieldValuesContext'],
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    moduleName: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    allowedFields: { type: Array, default: undefined },
    changeStage: { type: String, default: undefined },
    isTicketClosed: { type: Boolean, default: false },
    isTicketResolve: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    hiddenFields: {
      type: Array,
      default() {
        return []
      },
    },
    isRequiredFn: {
      type: Function,
      default: (e) => e,
    },
    singleFieldRulesStateFn: {
      type: Function,
      default: (e) => e,
    },
  },
  data() {
    this.stageConstants = stageConstants
    return {}
  },
  computed: {
    ...authComputed,
    ...MspConfigComputed,
    suggestedCategoryId() {
      let value
      const fieldValue =
        (
          (this.suggestedFieldValuesContext || {}).suggestedFieldValues || []
        ).find((i) => i.refId === this.resource.id) || {}
      if ((fieldValue || {}).suggestedCategoryId) {
        value = fieldValue.suggestedCategoryId
      }
      return value
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

<style lang="less" scoped>
.bordered-bottom {
  border-bottom: 1px solid var(--border-color);
}
</style>
